<template>
<form-wizard color="#7367F0" :title="null" :subtitle="null" finish-button-text="Kurulumu Başlat" back-button-text="Geri" next-button-text="İleri" class="steps-transparent mb-3">

    <!-- accoint details tab -->
    <tab-content title="Kazanım Yükleme">
        <b-row>
            <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                    Kazanım Yükleme
                </h5>
                <small class="text-muted">
                    Lütfen Örnek Kazanım 'Excell' Dosyasını Yükleyin
                </small>
                <a href="https://api.uzaktanokulsistemi/SABLON/SABLON-KAZANIM.xlsx" target="_blank">ÖRNEK ŞABLON DOSYASI</a>
            </b-col>
            <b-col md="12">
                <b-form-file placeholder="Dosya Yükleyin" drop-placeholder="Dosyayı Buraya Bırakın..." />
            </b-col>
        </b-row>
    </tab-content>

    <!-- accoint details tab -->
    <tab-content title="Video Yükleme">
        <b-row>
            <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                    Video Yükleme
                </h5>
                <small class="text-muted">
                    Lütfen Örnek Video 'Excell' Dosyasını Yükleyin
                </small>
                <a href="https://api.uzaktanokulsistemi.com/SABLON/SABLON-VIDEO.xlsx" target="_blank">ÖRNEK ŞABLON DOSYASI</a>
            </b-col>
            <b-col md="12">
                <b-form-file placeholder="Dosya Yükleyin" drop-placeholder="Dosyayı Buraya Bırakın..." />
            </b-col>
        </b-row>
    </tab-content>

    <!-- accoint details tab -->
    <tab-content title="Döküman Yükleme">
        <b-row>
            <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                    Döküman Yükleme
                </h5>
                <small class="text-muted">
                    Lütfen Örnek Döküman 'Excell' Dosyasını Yükleyin
                </small>
                <a href="https://api.uzaktanokulsistemi.com/SABLON/SABLON-DOKUMAN.xlsx" target="_blank">ÖRNEK ŞABLON DOSYASI</a>
            </b-col>
            <b-col md="12">
                <b-form-file placeholder="Dosya Yükleyin" drop-placeholder="Dosyayı Buraya Bırakın..." />
            </b-col>
        </b-row>
    </tab-content>

    <!-- accoint details tab -->
    <tab-content title="Test Yükleme">
        <b-row>
            <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                    Test Yükleme
                </h5>
                <small class="text-muted">
                    Lütfen Örnek Döküman 'Test' Dosyasını Yükleyin
                </small>
                <a href="https://api.uzaktanokulsistemi.com/SABLON/SABLON-TEST.xlsx" target="_blank">ÖRNEK ŞABLON DOSYASI</a>
            </b-col>
            <b-col md="12">
                <b-form-file placeholder="Dosya Yükleyin" drop-placeholder="Dosyayı Buraya Bırakın..." />
            </b-col>
        </b-row>
    </tab-content>

</form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormFile
} from 'bootstrap-vue'
export default {
    components: {
        FormWizard,
        TabContent,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BFormFile
    },
}
</script>
